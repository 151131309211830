import React from "react";
import { Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../Core";
import Container from "../Layout/Container";

interface Props {
  text: string;
  title: string;
  buttonText: string;
}

const TextCTA = ({ buttonText, text, title }: Props) => (
  <>
    {/* <!-- CTA section --> */}
    <Section
      py={["80px", "50px", "50px", "50px"]}
      bg="#054265"
      className="position-relative"
    >
      <Container>
        <Row className="justify-content-center">
          <Col lg="12" xl="12">
            <Box
              mb="20px"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Title mb="10px !important" textColor="light">
                {title}
              </Title>
              <Text textAlign="center" style={{ maxWidth: 800 }} color="white" opacity={0.9}>
                {text}
              </Text>
            </Box>
            <div className="text-center">
              <Button type="submit" borderRadius={10}>
                {buttonText}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default TextCTA;
