import React from "react";
import { Helmet } from "react-helmet"

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/internal-stores/Hero";
import Content from "../sections/internal-stores/Content";
import ImageRight from "../components/Content/ImageRight";
import ImageLeft from "../components/Content/ImageLeft";
import TextCTA from "../components/Content/TextCTA";
import Testimonial from "../sections/landing1/Testimonial";

const About = () => {
  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>Solutions for retailers & hospitality</title>
          <meta name="description" content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK." />
        </Helmet>
        <Hero title="Solutions for retail & hospitality" />
        <Content />
        <ImageRight
          buttons={[
            {
              text: "Learn more",
              color: "primary",
              to: "/products/alert-for-crime-partnerships",
              variant: "solid",
            },
          ]}
          text="Working closely with BIDs and CRPs across the country to create a modern solution that's more intuitive and easier to use, which ultimately increases community engagement."
          preTitle="Alert"
          title="Powerful intelligence sharing designed for users"
        />
        <ImageLeft
          buttons={[
            {
              text: "Learn more",
              color: "primary",
              to: "/products/smart-radio-for-crime-partnerships",
              variant: "solid",
            },
          ]}
          text="Working closely with BIDs and CRPs across the country to create a modern solution that's more intuitive and easier to use, which ultimately increases community engagement."
          preTitle="SmartRadio"
          title="Next-gen radios for ShopWatch systems"
        />
        <TextCTA
          buttonText="Book a demo"
          text="Book an online demo with one of our product experts to see see our solutions in action."
          title="Like what you see?"
        />
        <ImageRight
          buttons={[
            {
              text: "Learn more",
              color: "primary",
              to: "/products/insight-for-crime-partnerships",
              variant: "solid",
            },
          ]}
          text="Working closely with BIDs and CRPs across the country to create a modern solution that's more intuitive and easier to use, which ultimately increases community engagement."
          preTitle="Insight"
          title="Member analytics and management"
        />
        <ImageLeft
          buttons={[
            {
              text: "Learn more",
              color: "primary",
              to: "/products/bwv-for-crime-partnerships",
              variant: "solid",
            },
          ]}
          text="Working closely with BIDs and CRPs across the country to create a modern solution that's more intuitive and easier to use, which ultimately increases community engagement."
          preTitle="SmartBWV"
          title="Cloud enable smart BWV solution"
        />
        <Testimonial />
      </PageWrapper>
    </>
  );
};
export default About;
